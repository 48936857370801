import Biz2 from "../static/bizmsm/2.jpg";
import Biz3 from "../static/bizmsm/3.jpg";
import Biz4 from "../static/bizmsm/4.jpg";
import Biz5 from "../static/bizmsm/5.jpg";
import Biz6 from "../static/bizmsm/6.jpg";
import Biz7 from "../static/bizmsm/7.jpg";
import Biz8 from "../static/bizmsm/8.jpg";
import Biz9 from "../static/bizmsm/9.jpg";
import Biz10 from "../static/bizmsm/10.jpg";

export const bizmsmPics = [
  Biz2,
  Biz3,
  Biz4,
  Biz5,
  Biz6,
  Biz7,
  Biz8,
  Biz9,
  Biz10,
];
