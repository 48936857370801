import React from "react";
import Tags from "./Tags";

const AboutMe = (props) => {
  return (
    <div id="about" className="aboutMeWrapper">
      <h3 className="aboutMe-title animatedSection animated">About Me</h3>
      <p className="animatedSection animated">
      I am a detail-oriented and self-starting software engineer with passion and experience on both frontend and backend development.
        <br/>
        I have extensive knowledge of multiple frontend frameworks including Angular & React. Also, I am proficient in an assortment of web development technologies and tools.
        <br />
        <br />
        <span className="onlyMobileAndTabletShown animatedSection animated">
          Please feel free to browse around via:
        </span>
      </p>
      <Tags className="onlyMobileAndTabletShown animatedSection animated" />
    </div>
  );
};

export default AboutMe;
